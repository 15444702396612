<template>
<b-container class="pt-4">
    <b-row class="mb-4">
      <b-col lg="9" class="text-initial">
        <curva-title :title="$t('cart.cart')" v-if="getCartStep === 1"/>
        <div v-else-if="getCartStep === 2" class="d-flex justify-content-between">
          <curva-title :title="$t('cart.address')"/>
          <router-link :to="{ name:'addAddress' }">
          <b-button class="curva-icon-btn">+ {{ $t('cart.addAddress') }}
          </b-button></router-link>
        </div>
        <div v-else class="text-initial">
          <curva-title :title="$t('cart.payment')"/>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="9">
        <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                    :leave-active-class="`animated ${animated.exit}`">
          <router-view/>
        </transition>
      </b-col>
      <b-col lg="3">
        <div class="order-summary">
          <p class="title text-center">{{ $t('cart.summary') }}</p>
          <b-row>
            <b-col class="text-start">{{ $t('cart.tItems') }}</b-col>
            <b-col class="text-end">
              {{getSummery.totalItem}}
            </b-col>
          </b-row>
          <b-row>
            <b-col  class="text-start">{{ $t('cart.subtotal') }}</b-col>
            <b-col class="text-end">
              {{getSummery.subtotal}} <span class="currency">{{$t('egp')}}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-start">{{ $t('cart.tax') }}</b-col>
            <b-col class="text-end">
              0<span class="currency">{{$t('egp') }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-start">{{ $t('cart.fees') }}</b-col>
            <b-col class="text-end">
              {{ getDeliveryFees }}<span class="currency">{{$t('egp')}}</span>
            </b-col>
          </b-row>
          <b-row class="total-row">
            <b-col class="text-start">{{ $t('cart.total') }}</b-col>
            <b-col class="text-end">{{getSummery.total + getDeliveryFees}}
            <span class="currency">{{$t('egp')}}</span>
            </b-col>
          </b-row>
          <b-row v-if="getCartCount"><b-col lg="12">
            <b-button v-if="getCartStep === 1" class="curva-granola-btn small-rounded-btn" block
                      @click="$router.push({name: 'address'}); setFormStep(1) ">{{ $t('cart.step1') }}
            </b-button>
            <b-button v-else-if="getCartStep === 2" class="curva-granola-btn small-rounded-btn"
                      block @click="setFormStep(2)">{{ $t('cart.step2') }}
            </b-button>
            <b-button v-else class="curva-granola-btn small-rounded-btn"
                      block @click="placeOrder()">{{ $t('cart.step3') }}
            </b-button>
          </b-col></b-row>
        </div>
      </b-col>
    </b-row>
</b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import CartServices from '@/modules/cart/services/cart.services'
export default {
  data () {
    return {
      animated: { enter: 'fadeInUp', exit: 'fadeOut' },
      tableHeader: [
        { key: this.$t('cart.name') },
        { key: this.$t('cart.size') },
        { key: this.$t('cart.unitPrice') },
        { key: this.$t('cart.quantity') },
        { key: this.$t('cart.subtotal') },
        { key: this.$t('cart.action') }
      ],
      addresses: [],
      selected: require('@/assets/images/curva/address-selected.png')
    }
  },
  computed: {
    ...mapGetters(['getCartCount', 'getCart', 'getCartStep', 'getSummery', 'getDeliveryFees', 'getTerms'])
  },
  methods: {
    setFormStep (data) {
      if (data === 2) {
        if (this.getDeliveryFees) {
          this.$store.commit('cartStep', data)
          this.$router.push({ name: 'payments' })
        } else {
          core.showSnackbar('error', this.$t('cart.selectAddress'))
        }
      }
    },
    placeOrder () {
      if (this.getTerms) {
        const order = this.$store.state.cart.order
        const obj = {
          address: order.address,
          notes: order.notes ? order.notes : null,
          shipping: this.getDeliveryFees
        }
        CartServices.placeOrder(obj).then(res => {
          this.$router.push({ name: 'invoices', params: { client_id: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).id : null, order_id: res.data.data.order_id } })

          // this.$store.dispatch('getCartContent')
        })
      } else {
        core.showSnackbar('error', this.$t('forms.terms'))
      }
    }
  },
  mounted () {
    this.$store.dispatch('getCartContent')
    core.index()
  }
}
</script>
